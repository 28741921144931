import React from "react"
import styled from "styled-components"
import Section from "./Section"

const ResponsiveHeading = ({ children }) => (
  <Section>
    <Text>{children}</Text>
  </Section>
)

export const Text = styled.h3`
  text-align: left;
  color: #af8ed8;
  font-family: "Fieldwork Geo";
  font-weight: 600;
  font-size: 16px;
  padding: 23px 0 9px;
  margin: 0px;
  border-bottom: 1px solid #aaa;
  margin-bottom: 36px;
  @media (min-width: 767px) {
    display: none;
  }
`

export default ResponsiveHeading
