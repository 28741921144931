import styled from "styled-components"

const Section = styled.div`
  margin: 0 auto;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  width: 1140px;

  @media (max-width: 1199px) {
    width: 960px;
  }
  @media (max-width: 991px) {
    width: 750px;
  }
  @media (max-width: 767px) {
    width: 540px;
  }
  @media (max-width: 575px) {
    width: 100%;
  }
`

export default Section
