import React from "react"
import { graphql } from "gatsby"
import axios from "axios"
import queryString from "query-string"
import Layout from "../../components/core/layout"
import WithLocation from "../../components/core/Navigation/WithLocation"
import ADCard from "../../components/store/apps/AppDetails/AppDetailsCard"
import ADSearchBar from "../../components/store/apps/AppDetails/AppDetailsSearchBar"
import ResponsiveHeading from "../../components/core/ResponsiveHeading"
import SEO from "../../components/core/seo"

class ApplicationsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeCategoryIndex: 0,
      appCategories: [],
      appListYCord: 0,
      displayApps: [],
      filterSelected: false,
      fixedSearchBar: false,
      searchValue: "",
      selectedApp: null,
      navigatedIn: false,
    }
    this.appDetailsSearch = React.createRef()
    this.appDetailsCard = React.createRef()
  }

  componentDidMount = () => {
    const { data, navigate, search } = this.props
    const appCategories = data["dataJson"]["AppCategories"]
    this.setState({ appCategories }, () => {
      const parsedQueryString = Object.entries(search)
      const searchQuery = parsedQueryString[0]
      if (
        searchQuery &&
        searchQuery.length &&
        (searchQuery[0] === "manifest_url" ||
          searchQuery[0] === "bundle_id" ||
          searchQuery[0] === "name")
      ) {
        const searchValue = searchQuery[1];
        this.retrieveAppFromSearchEndpoint(searchValue)
      } else {
        navigate("/store")
      }
    })
  }

  retrieveAppFromSearchEndpoint = query => {
    const { navigate } = this.props
    const qs = queryString.stringify({ query })
    const url = `${process.env.SEARCH_ENDPOINT}${qs}`
    axios({
      method: 'GET',
      url
    }).then(res => {
      const { data } = res.data;
      if (data.length) {
        const selectedApp = data[0]
        this.setState({ selectedApp })
      } else {
        navigate("/store")
      }
    }).catch(() => {
      navigate("/store")
    });
  }

  componentDidUpdate = () => {
    if (this.appDetailsSearch.current) {
      window.addEventListener("scroll", this.handleScroll)
    }
  }

  componentWillUnmount = () => {
    if (this.appDetailsSearch.current) {
      window.removeEventListener("scroll", this.handleScroll)
    }
  }
  
  handleScroll = e => {
    if (this.appDetailsSearch.current) {
      const { y } = this.appDetailsSearch.current.getBoundingClientRect()
      if (y <= 0 && window.scrollY >= 65) {
        this.appDetailsSearch.current.style.position = "fixed"
        this.appDetailsSearch.current.style.top = 0
        this.appDetailsSearch.current.style.zIndex = 2
        this.appDetailsCard.current.style.marginTop = "40px"
      } else {
        this.appDetailsSearch.current.style.position = "relative"
        this.appDetailsCard.current.style.marginTop = 0
      }
    }
  }

  developerLinkOnClick = l => {
    window.open(l)
  }

  appDetailsCategoryOnSelect = i =>
    this.setState({ activeCategoryIndex: parseInt(i) })

  appDetailsSearchValueOnChange = val => this.setState({ searchValue: val })

  appDetailsSearchOnClick = () => {
    const { activeCategoryIndex, searchValue } = this.state
    const { navigate } = this.props
    navigate("/store", {
      state: {
        navStateCategoryIndex: activeCategoryIndex,
        navStateSearchValue: searchValue.trim(),
      },
      replace: false,
    })
  }

  render() {
    const {
      activeCategoryIndex,
      appCategories,
      searchValue,
      selectedApp,
    } = this.state

    return (
      <Layout>
        <ResponsiveHeading>Explore KaiOS</ResponsiveHeading>
        {selectedApp && (
          <React.Fragment>
            <SEO
              title={`${selectedApp["name"]} - KaiOS App`}
              lang="en"
              meta={[
                {
                  property: `appstore:store_id`,
                  content: selectedApp["display"],
                },
                {
                  property: `appstore:bundle_id`,
                  content: selectedApp["bundle_id"],
                },
                {
                  property: `appstore:developer_url`,
                  content: selectedApp["developer_url"],
                },
              ]}
            />
            <ADSearchBar
              activeCategoryIndex={activeCategoryIndex}
              appCategories={appCategories}
              searchValue={searchValue}
              appDetailsCategoryOnSelect={this.appDetailsCategoryOnSelect}
              appDetailsSearchValueOnChange={this.appDetailsSearchValueOnChange}
              appDetailsSearchOnClick={this.appDetailsSearchOnClick}
              ref={this.appDetailsSearch}
            />
            <ADCard
              category={selectedApp["category"]}
              createdAt={selectedApp["created_at"]}
              description={selectedApp["description"]}
              developer={selectedApp["developer"]}
              website={selectedApp["developer_url"]}
              image={selectedApp["thumbnail_url"]}
              name={selectedApp["name"]}
              size={selectedApp["size"]}
              type={selectedApp["type"]}
              version={selectedApp["version"]}
              developerLinkOnClick={() =>
                this.developerLinkOnClick(
                  selectedApp["developer_url"]
                )
              }
              ref={this.appDetailsCard}
            />
          </React.Fragment>
        )}
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    dataJson {
      AppCategories
    }
  }
`

export default WithLocation(ApplicationsPage)
