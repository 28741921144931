import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { FaSearch } from "react-icons/fa"

const ADSearchBar = forwardRef(
  (
    {
      activeCategoryIndex,
      appCategories,
      appDetailsSearchValueOnChange,
      appDetailsCategoryOnSelect,
      appDetailsSearchOnClick,
      searchValue,
    },
    ref
  ) => {
    const data = useStaticQuery(graphql`
      query {
        downArrow: file(relativePath: { eq: "dropdown-arrow.png" }) {
          childImageSharp {
            id
            fixed(width: 5, height: 5) {
              base64
              tracedSVG
              aspectRatio
              srcWebp
              srcSetWebp
              originalName
            }
          }
        }
      }
    `)
    const downArrow = data["downArrow"]["childImageSharp"]["fixed"]["base64"]

    return (
      <Container justifyContent="center" ref={ref}>
        <TextContainer>
          <Text>Apps</Text>
        </TextContainer>
        <DropdownContainer>
          <Dropdown
            backgroundImage={downArrow}
            value={activeCategoryIndex}
            onChange={e => appDetailsCategoryOnSelect(e.target.value)}
          >
            {appCategories.map((aC, i) => (
              <option key={aC} value={i}>
                {aC}
              </option>
            ))}
          </Dropdown>
        </DropdownContainer>
        <SearchFieldContainer>
          <SearchButton
            style={{ color: "#6f02b5" }}
            onClick={appDetailsSearchOnClick}
          />
          <SearchField
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={e => appDetailsSearchValueOnChange(e.target.value)}
          />
        </SearchFieldContainer>
      </Container>
    )
  }
)

ADSearchBar.propTypes = {
  activeCategoryIndex: PropTypes.number,
  appCategories: PropTypes.array,
  appDetailsSearchValueOnChange: PropTypes.func,
  appDetailsCategoryOnSelect: PropTypes.func,
  appDetailsSearchOnClick: PropTypes.func,
  searchValue: PropTypes.string,
}

ADSearchBar.defaultProps = {
  activeCategoryIndex: 0,
  appCategories: [],
  appDetailsSearchValueOnChange: null,
  appDetailsCategoryOnSelect: null,
  appDetailsSearchOnClick: null,
  searchValue: "",
}

const Container = styled.div`
  background: #fff;
  border-bottom: 1px solid #aaa;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const TextContainer = styled.div`
  background-color: #fff;

  @media (max-width: 767px) {
    width: 15%;
  }
`

const Text = styled.h3`
  color: #6f02b5;
  width: 60px;
  font-size: 16px;
  margin-right: 10px;
  font-weight: 600;
  text-align: center;

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0px;
  }
  @media (max-width: 575px) {
    font-size: 12px;
  }
`

const DropdownContainer = styled.div`
  width: 17%;
  height: 100%;
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
  margin-left: 2%;
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    width: 80%;
    border-right: 0;
  }
`

export const Dropdown = styled.select`
  width: 98%;
  height: 100%;
  border: none;
  appearance: none;
  padding: 10px;
  background: ${({ backgroundImage }) =>
    `url(${backgroundImage}) 96% / 10% no-repeat #fff`};
  font-size: 14px;

  ::-ms-expand {
    display: none;
  }

  @media (max-width: 767px) {
    background: ${({ backgroundImage }) =>
      `url(${backgroundImage}) 96% / 8% no-repeat #fff`};
    font-size: 12px;
  }
`

const SearchFieldContainer = styled.div`
  width: 28%;
  height: 100%;
  border-right: 1px solid #aaa;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 767px) {
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    width: 100%;
    padding: 0px 8px;
  }
`

export const SearchField = styled.input`
  width: 100%;
  height: 100%;
  padding: 10px;
  border: none;
  font-size: 14px;

  @media (max-width: 575px) {
    font-size: 12px;
    ::placeholder {
      font-size: 14px;
    }
  }
`

const SearchButton = styled(FaSearch)`
  position: absolute;
  right: 26%;
  cursor: pointer;

  @media (max-width: 767px) {
    right: 6%;
  }
`

export default ADSearchBar
