import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment"
import FlexBox from "../../../core/FlexBox"

const ADCard = forwardRef(
  (
    {
      category,
      createdAt,
      description,
      developer,
      developerLinkOnClick,
      image,
      name,
      size,
      type,
      website,
      version,
    },
    ref
  ) => (
    <Container justifyContent="center" ref={ref}>
      <ADCardContainer>
        <TitleContainer>
          <ImageContainer>
            <Image src={image} />
          </ImageContainer>
          <DetailsContainer
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Category>{category}</Category>
            <Name>{name}</Name>
            <Developer>{developer}</Developer>
          </DetailsContainer>
        </TitleContainer>
        <SubSectionContainer>
          <SubSectionHeading>Description</SubSectionHeading>
          <DescriptionContent>{description}</DescriptionContent>
        </SubSectionContainer>
        <SubSectionContainer>
          <SubSectionHeading>Specifications</SubSectionHeading>
          <SpecificationsBox>
            <SpecificationType>Developer</SpecificationType>
            <SpecificationValue>{developer}</SpecificationValue>
          </SpecificationsBox>
          <SpecificationsBox>
            <SpecificationType>Website</SpecificationType>
            <SpecificationValue link onClick={developerLinkOnClick}>
              {website}
            </SpecificationValue>
          </SpecificationsBox>
          <SpecificationsBox>
            <SpecificationType>Category</SpecificationType>
            <SpecificationValue>{category}</SpecificationValue>
          </SpecificationsBox>
          <SpecificationsBox>
            <SpecificationType>Type</SpecificationType>
            <SpecificationValue>{`${type[0].toUpperCase()}${type.slice(
              1
            )}`}</SpecificationValue>
          </SpecificationsBox>
          <SpecificationsBox>
            <SpecificationType>Size</SpecificationType>
            <SpecificationValue>{`${(size / 1000000).toFixed(
              2
            )} MB`}</SpecificationValue>
          </SpecificationsBox>
          <SpecificationsBox>
            <SpecificationType>Version</SpecificationType>
            <SpecificationValue>{version}</SpecificationValue>
          </SpecificationsBox>
          <SpecificationsBox>
            <SpecificationType>Created On</SpecificationType>
            <SpecificationValue>
              {moment(createdAt).format("MMM DD, YYYY")}
            </SpecificationValue>
          </SpecificationsBox>
        </SubSectionContainer>
      </ADCardContainer>
    </Container>
  )
)

ADCard.propTypes = {
  bundle_id: PropTypes.string,
  category: PropTypes.string,
  createdAt: PropTypes.number,
  description: PropTypes.string,
  developer: PropTypes.string,
  developerLinkOnClick: PropTypes.func,
  image: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
  type: PropTypes.string,
  website: PropTypes.string,
  version: PropTypes.string,
}

ADCard.defaultProps = {
  bundle_id: "",
  category: "",
  createdAt: 0,
  description: "",
  developer: "",
  developerLinkOnClick: null,
  image: "",
  name: "",
  size: 0,
  type: "",
  website: "",
  version: "",
}

export default ADCard

const Container = styled(FlexBox)`
  padding: 60px 0px;
`

const ADCardContainer = styled.div`
  width: 50%;
  background-color: #fff;
  border-radius: 10px;
  padding: 60px 60px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid #efefef;

  @media (max-width: 1199px) {
    width: 70%;
    padding: 60px 60px;
  }

  @media (max-width: 767px) {
    width: 80%;
    padding: 20px 20px;
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 575px) {
    justify-content: center;
    align-items: center;
    margin-left: 0px;
  }
`

const ImageContainer = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 10px;
  min-width: 80px;
  min-height: 80px;
  width: 80px;
  height: 80px;

  @media (min-width: 1199px) {
    margin-right: 10px;
  }

  @media (min-width: 767px) {
    margin-right: 30px;
    min-width: 120px;
    min-height: 120px;
    width: 120px;
    height: 120px;
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const DetailsContainer = styled(FlexBox)`
  width: 60%;
  padding-bottom: 20px;

  @media (max-width: 767px) {
    padding-left: 10px;
    width: 50%;
    padding: 0;
    padding-top: 15px;
  }

  @media (max-width: 575px) {
    width: 100%;
  }
`

const Category = styled.span`
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
  color: #6f02b5;
  margin: 3px 0;
  font-weight: 400;
  margin: 0;
  font-family: "Fieldwork Geo";
`

const Name = styled.h3`
  font-size: 17px;
  line-height: 19px;
  font-weight: 500;
  color: #323232;
  margin: 0px 0px 3px 0;
  letter-spacing: 0.4px;
  word-break: break-all;
  font-family: "Fieldwork Geo";
  margin: 5px 0px;
`

const Developer = styled.h4`
  font-size: 12px;
  font-weight: 600;
  color: #aaa;
  word-break: break-all;
  font-family: "Fieldwork Geo";
`

const SubSectionContainer = styled.div`
  margin: 20px 0;
  padding-left: 10px;
  margin-bottom: 20px;

  @media (max-width: 575px) {
    padding-left: 0px;
  }
`

const SubSectionHeading = styled.h4`
  font-size: 12px;
  font-weight: 600;
  color: #aaa;
  word-break: break-all;
  margin-bottom: 8px;
`

const DescriptionContent = styled.h4`
  font-size: 16px;
  font-weight: 400;
  color: #000;
  letter-spacing: 0.4px;
  font-family: "Fieldwork Geo";

  @media (max-width: 767px) {
    font-size: 14px;
  }
`

const SpecificationsBox = styled(FlexBox)`
  padding: 5px 0px;
  border-bottom: 1px solid #aaa;
  margin-top: 8px;
`

const SpecificationType = styled.h3`
  color: #000;
  width: 40%;
  font-weight: 300;
  font-size: 16px;
  font-family: "Fieldwork Geo";

  @media (max-width: 767px) {
    font-size: 13px;
  }
  @media (max-width: 575px) {
    font-size: 10px;
  }
`

const SpecificationValue = styled.h3`
  color: ${({ link }) => (link ? "#0000ee" : "#aaa")};
  font-size: 16px;
  cursor: ${({ link }) => (link ? "pointer" : null)};
  font-family: "Fieldwork Geo";
  text-decoration: ${({ link }) => (link ? "underline" : "none")};
  @media (max-width: 767px) {
    font-size: 13px;
  }
  @media (max-width: 575px) {
    font-size: 10px;
  }
`
